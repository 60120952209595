import React, { useEffect, useRef } from "react";
import { graphql } from "gatsby";
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import About from "components/Saas/About";
import Footer from "components/Digital/Footer";
import Services from "components/Saas/Services";
import Navbar from "components/Navbars/DigitalNav/OnePageNav";

const ServicesTemplate = ({ data }) => {
  const navbarRef = useRef(null);

  const { markdownRemark } = data || {};

  const { frontmatter, html } = markdownRemark || {};

  console.log(frontmatter, "frontmatter");

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  return (
    <MainLayout>
      <Navbar navbarRef={navbarRef} />
      <main className="services-page style-5">
        <About noPaddingTop content={html} title={frontmatter?.title} />
        <Services data={frontmatter?.services} />
      </main>
      <Footer noWave />
    </MainLayout>
  );
};

export const Head = ({ data }) => {
  const { markdownRemark } = data || {};

  const { frontmatter } = markdownRemark || {};

  return (
    <>
      <title>{frontmatter?.title}</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default ServicesTemplate;

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        content1
        content2
        services {
          title
          description
          images
        }
      }
    }
  }
`;
