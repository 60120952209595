import React from "react";
import Content3 from "./Content3";
import aboutData from "data/Saas/about.json";

const About = ({ noPaddingTop, title, content }) => {
  return (
    <section
      className={`about ${noPaddingTop ? "pt-0" : "section-padding"} style-5`}
      data-scroll-index="1"
    >
      <Content3 title={title} content={content} />
    </section>
  );
};

export default About;
