import React from "react";

const Content = ({ title, content }) => {
  return (
    <div className="content pb-0">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 order-2 order-lg-0">
            <div className="section-head mb-30 style-5">
              <h2>
                <span>{title}</span>{" "}
              </h2>
            </div>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {/* <p>
              {data?.content1}
            </p>
            <p className="mt-20">
            {data?.content2}
            </p> */}
          </div>
          <div className="col-lg-5 order-0 order-lg-2">
            <div className="img main-img3">
              <img
                src="/assets/img/about/about_s5_3_1.png"
                alt=""
                className="img-body"
              />
              <img src="/assets/img/about/about_s5_3_2.png" alt="" />
              <img src="/assets/img/about/about_s5_3_3.png" alt="" />
              <img src="/assets/img/about/about_s5_3_4.png" alt="" />
              <img src="/assets/img/about/about_s5_3_5.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
