import React from "react";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import services from "data/Saas/services.json";
import servicesRTL from "data/Saas/services-rtl.json";

import "swiper/css";

const Services = ({ data }) => {
  return (
    <section className="services section-padding bg-white  style-6">
      <div className="content">
        <div className="services-slider position-relative style-6">
          <Swiper
            className="swiper-container"
            slidesPerView={6}
            centeredSlides={true}
            spaceBetween={0}
            speed={1000}
            pagination={false}
            navigation={false}
            mousewheel={false}
            keyboard={true}
            autoplay={{
              delay: 4000,
            }}
            loop={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              480: {
                slidesPerView: 1,
              },
              787: {
                slidesPerView: 2,
              },
              991: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 6,
              },
            }}
          >
            {data?.map((service, index) => (
              <SwiperSlide key={index}>
                <Link to="/page-services-5" className="service-card style-6">
                  <div className="icon">
                    <img src={service.images} alt="" />
                  </div>
                  <div className="info">
                    <h5>{service.title}</h5>
                    {/* <div className="text">{service.text}</div> */}
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Services;
